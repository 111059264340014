import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Link from '/src/components/LocalizedLink';
import ForthcomingCourses from '../components/ForthcomingCourses';
import AplicationProcess from '../components/AplicationProcess';

import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';
import IconArrowRightRed from '../assets/images/svg/arrow-right-red.inline.svg';
import iconCalendar from '../assets/images/svg/calendar.svg';
import iconClock from '../assets/images/svg/clock.svg';
import iconTick from '../assets/images/svg/tick.svg';

import imgBenefit1 from '../assets/images/spanish-courses/Fun, Dynamic Lessons.svg';
import imgBenefit2 from '../assets/images/spanish-courses/A New Skill For Life.svg';
import imgBenefit3 from '../assets/images/spanish-courses/Great Mix of Cultures.svg';
import imgBenefit5 from '../assets/images/spanish-courses/Small-Group Learning.svg';
import imgReview from '../assets/images/developing-teacher-course/Developing Teacher - Nicole & Agustina.png';
import iconQuotes from '../assets/images/trinity-cert-tesol-course-page/quotes.svg';
import imgDots from '../assets/images/trinity-cert-tesol-course-page/Dots.svg';
import imgTrinityCertCourse from '../assets/images/other-popular-courses/Trinity CertTESOL Course (1).png';
import imgTeachYoungLearners from '../assets/images/other-popular-courses/Teacher Young Learners (1).png';
import imgDevelopingTeacherCourse from '../assets/images/other-popular-courses/Developing Teacher Course.png';

import iconAccomodation from '../assets/images/student-support/Accommodation (1).svg';
import iconStudentVisa from '../assets/images/student-support/Student visas (1).svg';
import iconCulture from '../assets/images/student-support/Culture.svg';
import iconBureaucracy from '../assets/images/student-support/Bureaucracy.svg';

import fileTEFLIberiaBrochure from '../assets/files/TEFL Iberia brochure web.pdf';

import '../assets/styles/pages/spanish-courses-page.scss';
import ReviewsSection from '../components/ReviewsSection';
import Faq from '../components/Faq';
import { FormattedMessage } from 'react-intl';

const option2 = `<div class="benefit">
                    <img src='${iconTick}' alt="iconTick" />
                    <p class="c-text-16">Complete a Spanish taster course during your TEFL course.</p>
                </div>
                <div class="benefit">
                    <img src='${iconTick}' alt="iconTick" />
                    <p class="c-text-16">16.30-18.30 during weeks 1, 2 and 3 of your TEFL course (6 hours total).</p>
                </div>
                <div class="benefit">
                    <img src='${iconTick}' alt="iconTick" />
                    <p class="c-text-16">Beginner level Spanish.</p>
                </div>
                <div class="benefit">
                    <img src='${iconTick}' alt="iconTick" />
                    <p class="c-text-16">Price: 60€.</p>
                </div>
`;

const option1 = `<div class="benefit">
                    <img src='${iconTick}' alt="iconTick" />
                    <p class="c-text-16">Study 6 hours per week</p>
                </div>
                <div class="benefit">
                    <img src='${iconTick}' alt="iconTick" />
                    <p class="c-text-16">Start once you complete your TEFL course.</p>
                </div>
                <div class="benefit">
                    <img src='${iconTick}' alt="iconTick" />
                    <p class="c-text-16">09.30-11.30, Monday, Wednesday, Friday.</p>
                </div>
                <div class="benefit">
                    <img src='${iconTick}' alt="iconTick" />
                    <p class="c-text-16">All levels, beginner to advanced. </p>
                </div>
                <div class="benefit">
                    <img src='${iconTick}' alt="iconTick" />
                    <p class="c-text-16">Price: 200€/month.</p>
                </div>
`;

const communicativeApproach = `
The communicative approach is a teaching method that emphasises students interacting in real, ‘authentic’ scenarios in order to learn a language. Nowadays it is considered one of the most effective methods of language learning. You can read more about it 
 <a target="_blank" href="https://en.wikipedia.org/wiki/Communicative_language_teaching" class="red-link">here.</a> 
`;

const beginner = `
                <ul class='faq-list'>
                    <li class='faq-list__item'>Introduce yourself and meet other people.</li>
                    <li class='faq-list__item'>Describe people, objects, places, situations or weather.</li>
                    <li class='faq-list__item'>Express your likes and dislikes.</li>
                    <li class='faq-list__item'>Describe your family and friends.</li>
                    <li class='faq-list__item'>Describe the market, clothes and shopping.</li>
                    <li class='faq-list__item'>Make suggestions.</li>
                    <li class='faq-list__item'>Time, dates, numbers.</li>
                    <li class='faq-list__item'>Describe the seasons.</li>
                    <li class='faq-list__item'>Describe transport, cities, countries.</li>
                    <li class='faq-list__item'>Describe possessions.</li>
                </ul>
                `;
const loverIntermediate = `
                <ul class='faq-list'>
                <li class='faq-list__item'>Describe routines and habits.</li>
                <li class='faq-list__item'>Describe and narrate experiences and events in the past.</li>
                <li class='faq-list__item'>Discuss diet and lifestyle.</li>
                <li class='faq-list__item'>Use polite forms and give advice.</li>
                <li class='faq-list__item'>Talk about the future and plans.</li>
                <li class='faq-list__item'>Give directions and instructions.</li>
                <li class='faq-list__item'>Describe appearances.</li>
                </ul>
                `;
const intermediate = `
                <ul class='faq-list'>
                <li class='faq-list__item'>Describe experiences, anecdotes, stories and tales of the past.</li>
                <li class='faq-list__item'>Express your desires and feelings.</li>
                <li class='faq-list__item'>Talk about hypothetical situations.</li>
                <li class='faq-list__item'>Discuss fictional stories.</li>
                <li class='faq-list__item'>Express and justify your opinions.</li>
                <li class='faq-list__item'>Describe emotions and give advice.</li>
                <li class='faq-list__item'>Describe and respond to the news and current events.</li>
                <li class='faq-list__item'>Describe achievements.</li>
                <li class='faq-list__item'>Discuss the environment and social issues.</li>
                </ul>
                `;
const upperIntermediate = `
                <ul class='faq-list'>
                <li class='faq-list__item'>Participate in discussions and describe your viewpoint.</li>
                <li class='faq-list__item'>Retell stories in other peoples’ words.</li>
                <li class='faq-list__item'>Express hypothetical conditions.</li>
                <li class='faq-list__item'>Give detailed and accurate explanations of preferences, descriptions, personal experiences.</li>
                <li class='faq-list__item'>Tell anecdotes.</li>
                <li class='faq-list__item'>Describe wishes and regrets.</li>
                <li class='faq-list__item'>Discuss future hopes and plans.</li>
                <li class='faq-list__item'>Discuss the arts and culture.</li>
                </ul>
                `;
const advanced = `
                <ul class='faq-list'>
                <li class='faq-list__item'>Express yourself with ease, spontaneity and effectiveness in social, academic and professional scenarios.</li>
                <li class='faq-list__item'>Make jokes and use double meanings or irony.</li>
                <li class='faq-list__item'>Be able to understand a wide variety of discourses: literary texts, films, scholarly articles, television, etc.</li>
                <li class='faq-list__item'>Produce clear speech, fluent and well-structured with a controlled use of organisational patterns, connectors and cohesive devices.</li>
                <li class='faq-list__item'>Speculate and use vague language.</li>
                <li class='faq-list__item'>Discuss controversy.</li>
                <li class='faq-list__item'>Use language of persuasion, frustration, criticism, evaluation, negotiation.</li>
                </ul>
                `;
const SpanishCourses = ({ data }) => {
  const allPrismicCourses = data.allPrismicCourses.edges;
  const [isMore, setIsMore] = useState(false);

  return (
    <Layout
      title={{ id: 'spanishCourse.seo.title' }}
      description={{ id: 'spanishCourse.seo.description' }}
    >
      <div className="spanish-courses-page">
        <section className="c-first-section c-first-section--sm">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-9 col-xl-7">
                <div className="c-first-section__schedule">
                  <div className="item__wrapper">
                    <img src={iconCalendar} alt="" />
                    <span className="c-text-14">6 -12 months</span>
                  </div>
                  <div className="item__wrapper">
                    <img src={iconClock} alt="" />
                    <span className="c-text-14">20 hours per week of class</span>
                  </div>
                </div>
                <h1 className="c-first-section__title">Developing Teacher and Spanish Course </h1>
                <p className="c-first-section__description">
                  Achieve Spanish fluency while improving your teaching skills
                </p>
                <div className="c-first-section__btns">
                  <FormattedMessage id="url.apply.index">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--red">
                        <FormattedMessage id="menu.applyNow" />
                        <IconArrowRight />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="second-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="c-title-34 second-section__l">
                  Looking for a proven way to improve your Spanish while teaching English in Spain?
                </h2>
                <p className="c-text-18 description second-section__l">
                  Learning Spanish while improving your teaching skills will take your experience in
                  Spain to a whole new level. Make the most of your time here to learn the language
                  with our fun, dynamic, and practical Spanish lessons, plus advance your classroom
                  abilities with collaborative teaching workshops. No matter where you start, this
                  course will develop your skills in all areas!
                </p>
                <div className="c-benefit-list second-section__l">
                  <h4 className="c-text-16">This course is ideal for you if:</h4>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      You want to feel confident in your Spanish skills and deepen your teaching
                      knowledge.
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      You want to enrich your daily life in Spain and feel part of the community.
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">You are a dedicated student that loves to learn.</p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">You want to qualify for a long-term student visa.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wrapper">
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit1} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Fun, Dynamic Lessons</h3>
                      <p className="c-text-14">
                        Communicative classes are fun, but they are also a practical way to build
                        Spanish fluency fast.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit2} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">A New Skill For Life</h3>
                      <p className="c-text-14">
                        Make the most of your time in Spain and learn a new language.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit3} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Great Mix of Cultures</h3>
                      <p className="c-text-14">
                        TEFL Iberia hosts students from all over the world, each with their own
                        diverse culture to share.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit5} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Small-Group Learning</h3>
                      <p className="c-text-14">
                        With max. 10 students per class, instructors have time to devote to
                        everybody.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-section-includes c-faqs-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="block_l">
                  <div className="block_l__img">
                    <img src={imgReview} alt="" className="img-main" />
                    <img src={imgDots} alt="" className="dots" />
                  </div>
                  <div className="block_l__text">
                    <img src={iconQuotes} alt="" />
                    <p>
                      Learning at TEFL Iberia was amazing! I went into it knowing little to no
                      Spanish and after 2 months I have learned so much. The teachers work at your
                      pace and never make you feel pressured. They helped me with my confidence when
                      it comes to speaking Spanish in public. If you’re looking for a language
                      school TEFL Iberia is a great choice!
                      <br />
                      <strong>Nicole & Agustina</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="block_r">
                  <h2 className="c-title-34">What’s included?</h2>
                  <div className="c-benefit-list block_r__benefits">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        10 hours per week of Spanish classes in our Instituto Cervantes accredited
                        partner school.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">10 hours per week of Developing Teacher lessons.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Expert tuition from professional teachers.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Access to our beautiful school library, high-speed wifi, computers.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        All the paperwork necessary to process your student visa
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Job contacts and placement help – our host school hires exclusively from
                        TEFL Iberia graduates.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Dedicated course coordinator.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Cultural activities and social calendar at the school.
                      </p>
                    </div>
                  </div>
                  <div className="block_r__price block_r__price--full">
                    <h3 className="c-text-13">PER MONTH : </h3>
                    <h3 className="c-title-32">
                      275€
                      {/* <span className="lowercase">/month</span> */}
                    </h3>
                  </div>
                  <div className="block_r__price block_r__price--early-bird">
                    <h3 className="c-text-13">
                      PER YEAR:
                      <span className="notion">Enroll for 12 months and Save 300€!</span>
                    </h3>
                    <h3 className="c-title-32">3000€</h3>
                  </div>
                  <div className="block_r__btns">
                    <Link to="/apply/" className="c-btn c-btn--red">
                      Apply Now
                      <IconArrowRight />
                    </Link>
                    <a
                      href={fileTEFLIberiaBrochure}
                      className="c-btn c-btn--red-border"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download Brochure
                      <IconArrowRightRed />
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="block_r c-benefit-list">
                  <h2 className="c-title-34">What’s included?</h2>
                  <div className="c-benefit-list">
                    <Faq title="Option 1:" description={option2} />
                    <Faq title="Option 2:" description={option1} />
                  </div>
                  <div className="block_r__btns">
                    <Link to="/apply/" className="c-btn c-btn--red">
                      Apply Now
                      <IconArrowRight />
                    </Link>
                    <a
                      href={fileTEFLIberiaBrochure}
                      className="c-btn c-btn--red-border"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download Brochure
                      <IconArrowRightRed />
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <section className="fourth-section">
          <div className="container">
            <ForthcomingCourses
              cardData={allPrismicCourses?.slice(0, isMore ? allPrismicCourses?.length : 4)}
              title={{ id: 'forthcomingCourses.title.upcoming' }}
            />
            {allPrismicCourses?.length > 4 ? (
              <button
                onClick={() => setIsMore(!isMore)}
                className="c-btn c-btn--red-border load-more-dates"
              >
                {isMore ? 'Show Less Dates' : 'Load More Dates'}
                <IconArrowRightRed />
              </button>
            ) : null}
          </div>
        </section>

        <section className="fifth-section c-student-support-section">
          <div className="container">
            <h2 className="c-title-34">
              <FormattedMessage id="studentSupport.title" />
            </h2>
            <p className="c-text-18">
              <FormattedMessage id="studentSupport.description" />
            </p>
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconAccomodation} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.accommodation" />
                  </h3>
                  <FormattedMessage id="url.accommodation">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconStudentVisa} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.studentVisa" />
                  </h3>
                  <FormattedMessage id="url.working-as-a-teacher.student-visas">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconCulture} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.culture" />
                  </h3>
                  <FormattedMessage id="url.guide-to-living-and-teaching-english-in-barcelona">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <img src={imgDots} className="dots" alt="" />
                <div className="item">
                  <img src={iconBureaucracy} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.bureaucracy" />
                  </h3>
                  <FormattedMessage id="url.working-as-a-teacher.student-visas">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="fifth-section c-student-support-section">
          <div className="container">
            <h2 className="c-title-34">Student support</h2>
            <p className="c-text-18">Learn about our additional services</p>
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconAccomodation} alt="" />
                  <h3 className="c-title-22">Accomodation</h3>
                  <Link to="/accommodation/" className="c-btn c-btn--white">
                    Find Out More
                    <IconArrowRightRed />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconStudentVisa} alt="" />
                  <h3 className="c-title-22">Student Visa</h3>
                  <Link to="/working-as-a-teacher/student-visas" className="c-btn c-btn--white">
                    Find Out More
                    <IconArrowRightRed />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconCulture} alt="" />
                  <h3 className="c-title-22">Culture</h3>
                  <Link
                    to="/guide-to-living-and-teaching-english-in-barcelona/"
                    className="c-btn c-btn--white"
                  >
                    Find Out More
                    <IconArrowRightRed />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconBureaucracy} alt="" />
                  <h3 className="c-title-22">Bureaucracy</h3>
                  <Link to="/working-as-a-teacher/student-visas" className="c-btn c-btn--white">
                    Find Out More
                    <IconArrowRightRed />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="sixth-section">
          <AplicationProcess />
        </section>

        <section className="seventh-section">
          <div className="container">
            <h2 className="c-title-38">
              Start Your Overseas <span className="colored no-link">Adventure Today</span>
            </h2>

            <FormattedMessage id="url.apply.index">
              {(locale) => (
                <Link to={locale[0]} className="c-btn c-btn--red">
                  <FormattedMessage id="menu.signUpNow" />
                  <IconArrowRight />
                </Link>
              )}
            </FormattedMessage>
          </div>
        </section>

        <section className="eigth-section c-faqs-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <h3 className="c-title-34">How Spanish Levels Work </h3>
                {/* <h3 className="c-title-34">Course Curriculum and Timetable</h3> */}

                <p className="c-text-18">
                  The specific topics vary based on your level, consult the list to see what you
                  will learn.
                </p>

                <div className="assesment-block">
                  <h4 className="c-title-20">Want To See Where You Fit In?</h4>
                  {/* <p className="c-text-16">
                    You need to demonstrate a C1 level in order to be able to do the course.
                  </p> */}
                  {/* <Link to="/apply/" className="c-btn c-btn--red-border">
                    Apply Now
                    <IconArrowRightRed />
                  </Link> */}
                  <FormattedMessage id="url.apply.index">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--red-border">
                        <FormattedMessage id="menu.applyNow" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <Faq title="A1 Beginner" description={beginner} />
                <Faq title="A2 Lower Intermediate" description={loverIntermediate} />
                <Faq title="B1 Intermediate" description={intermediate} />
                <Faq title="B2 Upper Intermediate" description={upperIntermediate} />
                <Faq title="C1 Advanced" description={advanced} />
              </div>
            </div>
          </div>
        </section>

        <section className="nineth-section">
          <ReviewsSection
            hideAllReviews
            description={{ id: 'graduates.teflSpanishCourses.description' }}
            title={{ id: 'graduates.teflSpanishCourses.title' }}
          />
        </section>

        <section className="tenth-section c-faqs-section">
          <div className="container">
            <div className="c-faqs-section__title">
              <h2 className="c-title-34">Frequently Asked Questions</h2>
              <FormattedMessage id="url.courses.faq">
                {(locale) => (
                  <Link to={locale[0]} className="c-btn c-btn--white">
                    <FormattedMessage id="btn.viewMoreFaqs" />
                    <IconArrowRightRed />
                  </Link>
                )}
              </FormattedMessage>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Faq
                  description="Yes. Although our Spanish instructors conduct all courses in Spanish, they all speak some English and can assist with translations as needed. Due to our level system, you will be placed with other new learners."
                  title="I am a complete beginner. Does the teacher speak my language?"
                />
                <Faq
                  description="The specific content varies based on your level, but all classes are fun, dynamic, and taught in small groups (maximum 8 students), with a focus on speaking and listening. Teachers incorporate activities from the textbook and the interactive whiteboard into all lessons."
                  title="What will the classes be like?"
                />
                <Faq
                  description={communicativeApproach}
                  title="What is the ‘communicative approach’?"
                />
              </div>
              <div className="col-lg-6">
                <Faq
                  description="Yes, if you do the taster course you will only do 2 hours per week for 3 weeks, so you are not overloaded during your course. With the other courses, we have morning, afternoon and evening timetables so you can fit around your work schedule. "
                  title="Will I be able to combine this with my studies and work?"
                />
                <Faq
                  description="No. English is the only language used in the classroom and we want to show you how you can teach without using a word of the foreign language. However, you will get a lot more from your experience if you take the time to learn some Spanish and engage with the local culture."
                  title="Do I need to speak Spanish to teach English in Spain?"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="eleventh-section c-other-courses-section">
          <div className="container">
            <h2 className="c-title-34">
              Other <span className="colored no-link">Popular Courses</span>
            </h2>
            <p className="c-text-18">Take your training to the next level with TEFL Iberia</p>
            <div className="row">
              <div className="col-sm-6 col-lg-4">
                <FormattedMessage id="url.courses.one-month-160-hour-tefl-course.index">
                  {(locale) => (
                    <Link to={locale[0]} className="item">
                      <img
                        src={imgTrinityCertCourse}
                        alt="imgTrinityCertCourse"
                        className="item__img"
                      />
                      <p className="c-text-14">
                        <span className="item__price">1650€</span>
                      </p>
                      <p className="c-text-20">Trinity CertTESOL Course</p>
                    </Link>
                  )}
                </FormattedMessage>
              </div>
              <div className="col-sm-6 col-lg-4">
                <FormattedMessage id="url.teaching-kids-course">
                  {(locale) => (
                    <Link to={locale[0]} className="item">
                      <img
                        src={imgTeachYoungLearners}
                        alt="imgTeachYoungLearners"
                        className="item__img"
                      />
                      <p className="c-text-14">
                        <span className="item__price">300€ </span>
                        /3 day course
                      </p>
                      <p className="c-text-20">Teaching Young Learners</p>
                    </Link>
                  )}
                </FormattedMessage>
              </div>
              <div className="col-sm-6 col-lg-4">
                <FormattedMessage id="url.courses.developing-teacher-course">
                  {(locale) => (
                    <Link to={locale[0]} className="item">
                      <img
                        src={imgDevelopingTeacherCourse}
                        alt="imgDevelopingTeacherCourse"
                        className="item__img"
                      />
                      <p className="c-text-14">
                        <span className="item__price">120€ </span>
                        /month
                      </p>
                      <p className="c-text-20">Developing Teacher Course</p>
                    </Link>
                  )}
                </FormattedMessage>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicCourses(
      filter: {
        data: {
          show_on_these_pages: {
            elemMatch: { page: { eq: "developing-teacher-and-spanish-course" } }
          }
        }
      }
      sort: { order: ASC, fields: data___from }
    ) {
      edges {
        node {
          id
          data {
            name {
              text
            }
            from(formatString: "D MMMM YYYY")
            to(formatString: "D MMMM YYYY")
            price
            is_price_per_month
          }
        }
      }
    }
  }
`;

export default SpanishCourses;
